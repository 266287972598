.construction {
    margin: 20px auto;
    font-size: 2rem;
    >.underline {
        justify-content: center;
        >* {
            height: 1px;
            width: 0rem;
            background-color: var(--fg);
            margin-top: 10px;
            animation: underline-animation 1s ease-in 1s forwards;
        }
    }
}

@keyframes underline-animation {
    0% {
        width: 0;
    }
    100% {
        width: 4rem;
    }
}