@font-face {
    font-family: Rubik;
    font-display: swap;
    src: url('./fonts/Rubik-Light.woff') format('woff'),
         url('./fonts/Rubik-Light.ttf') format('truetype');
}

@font-face {
    font-family: Rubik;
    font-display: swap;
    font-weight: bold;
    src: url('./fonts/Rubik-Bold.woff') format('woff'),
         url('./fonts/Rubik-Bold.ttf') format('truetype');
}

@font-face {
    font-family: Rubik;
    font-display: swap;
    font-style: italic;
    src: url('./fonts/Rubik-LightItalic.woff') format('woff'),
         url('./fonts/Rubik-LightItalic.ttf') format('truetype');
}
