@import './colors.scss';
@import './fonts.scss';

.App {
    text-align: center;
    background-color: var(--bg);
    min-height: 100vh;
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--fg);
    padding: 0 20px;
    font-size: 1.1rem;
    font-family: Rubik;

    .flex-row {
        display: flex;
        flex-direction: row;
    }
    .flex-column {
        display: flex;
        flex-direction: column;
    }

    p {
        line-height: 1.8rem;
    }

    a {
        color: var(--fg);
        text-decoration: none;
    }

    .spacer {
        height: 2rem;
    }
}
