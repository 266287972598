:root {
    --bg: white;
    --fg: rgb(26, 24, 24);
    --color-grey : rgb(128, 128, 128);
    --color-red-wine: rgb(89, 25, 31);
}

/* Dark mode */
// @media (prefers-color-scheme: dark) {	
//     :root {
//         --bg: rgb(26, 24, 24);
//         --fg: white;
//     }
// }