.contact-details {
    margin: 5px auto;
    align-items: center;
    position: relative;
    width: 100%;
    >.details-cover {
        position: absolute;
        top: -0.1rem;
        bottom: -0.1rem;
        right: 0;
        width: 100%;
        animation: cover-animation .4s ease-in .6s forwards;
        background-color: var(--color-red-wine);
    }
}

@keyframes cover-animation {
    0% {
        width: 100%;
    }
    100% {
        width: 0%;
    }
}