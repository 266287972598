.navigation {
    margin-bottom: 50px;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    >.lines {
        margin-top: 30px;
        position: relative;
        width: 100%;
        font-size: 0;
    }
}


@media screen and (max-width: 600px) {
    .navigation {
        &.flex-row {
            flex-direction: column;
        }
    }
}