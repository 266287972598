.line {
    width: 100%;
    .path {
        fill: none;
        stroke: var(--color-grey);
        stroke-width: 1px;
        stroke-linecap: butt;
        stroke-linejoin: miter;
        stroke-opacity: 1;
    }
    &.red {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        overflow: hidden;
        transition: width .6s;
        white-space: nowrap;
        .path {
            stroke-dasharray: 536;
            stroke-dashoffset: 536;
            stroke: var(--color-red-wine);
            animation: red-line-animation 10s ease-in 2s infinite;
        }
    }
}

@keyframes red-line-animation {
    0%, 5% {
        stroke-dashoffset: 536;
    }
    45%, 55% {
        stroke-dashoffset: 0;
    }
    95%, 100% {
        stroke-dashoffset: 536;
    }
}