.social-link {
    margin: 0 10px;
    font-size: 2rem;
    >.underline {
        height: 1px;
        background-color: var(--fg);
        width: 100%;
        transition: width .4s ease-in;
        margin-top: -3px;
    }
    >a {
        >img {
            height: 3rem;
        }
    }
    &:hover {
        >.underline {
            width: 0%;
        }
    }
}